<template>
    <div>
		<el-table stripe border v-loading="loading" :data="dataList" >
			<el-table-column type="expand">
				<template slot-scope="scope">
					<el-form label-position="left" inline class="table-expand">
						<el-form-item :label="translate('LOGIN_INFO.SESSION_ID')">
							<span>{{scope.row.sessionId}}</span>
						</el-form-item>
						<el-form-item :label="translate('LOGIN_INFO.LOGIN_TYPE')">
							<span>{{dictTranslate(scope.row.loginType, dicts.LOGIN_TYPE)}}</span>
						</el-form-item>
						<el-form-item :label="translate('LOGIN_INFO.IP_ADDR')">
							<span>{{scope.row.ipaddr}}</span>
						</el-form-item>
						<el-form-item :label="translate('LOGIN_INFO.LOCATION')">
							<span>{{scope.row.location}}</span>
						</el-form-item>
						<el-form-item :label="translate('LOGIN_INFO.BROWSER')">
							<span>{{scope.row.browser}}</span>
						</el-form-item>
						<el-form-item :label="translate('LOGIN_INFO.OS')">
							<span>{{scope.row.os}}</span>
						</el-form-item>
						<el-form-item :label="translate('USER.USER_ID')">
							<span>{{scope.row.userId}}</span>
						</el-form-item>
						<template v-if="userInfos[scope.row.userId]">
							<el-form-item :label="translate('USER.NICKNAME')">
								<span>{{userInfos[scope.row.userId].nickname}}</span>
							</el-form-item>
							<el-form-item :label="translate('USER.LOGIN_NAME')">
								<span>{{userInfos[scope.row.userId].loginName}}</span>
							</el-form-item>
							<el-form-item :label="translate('USER.AVATAR')">
								<img v-if="userInfos[scope.row.userId].avatar" :src="userInfos[scope.row.userId].avatar" width="30" height="30">
							</el-form-item>
						</template>
					</el-form>
				</template>
			</el-table-column>
			<el-table-column :label="translate('LOGIN_INFO.ID')" prop="id" min-width="120" :show-overflow-tooltip="true"/>
			<!--            <el-table-column :label="translate('LOGIN_INFO.SESSION_ID')" prop="sessionId" width="240" :show-overflow-tooltip="true"/>-->
			<el-table-column :label="translate('USER.USER_ID')" prop="userId" min-width="120" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('LOGIN_INFO.LOGIN_TYPE')" prop="loginType" min-width="120" :show-overflow-tooltip="true">
				<template slot-scope="scope">{{dictTranslate(scope.row.loginType, dicts.LOGIN_TYPE)}}</template>
			</el-table-column>
			<el-table-column :label="translate('LOGIN_INFO.IP_ADDR')" prop="ipaddr" min-width="120" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('LOGIN_INFO.LOCATION')" prop="location" min-width="200" :show-overflow-tooltip="true"/>
			<!--            <el-table-column :label="translate('LOGIN_INFO.BROWSER')" prop="browser" min-width="120" :show-overflow-tooltip="true"/>-->
			<!--            <el-table-column :label="translate('LOGIN_INFO.OS')" prop="os" min-width="120" :show-overflow-tooltip="true"/>-->
			<el-table-column :label="translate('LOGIN_INFO.STATUS')" prop="status" min-width="180" :show-overflow-tooltip="true">
				<template slot-scope="scope">{{dictTranslate(scope.row.status, dicts.STATUS)}}</template>
			</el-table-column>
			<el-table-column :label="translate('COMMON.CREATE_TIME')" prop="createTime" min-width="180" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('COMMON.UPDATE_TIME')" prop="updateTime" min-width="180" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('COMMON.OPERATION')" align="center" class-name="small-padding fixed-width" min-width="180" fixed="right">
				<template slot-scope="scope">
					<el-button v-if="scope.row.status === '1'" size="mini" type="text"
							   @click="forceLogout(scope.row)"
							   v-permission="'SYS_LOGIN_INFO_FORCE_LOGOUT'">{{translate("Force logout")}}</el-button>
					<el-button v-if="scope.row.status === '1'" size="mini" type="text" icon="el-icon-delete"
							   @click="forceLogoutAll(scope.row)"
							   v-permission="'SYS_LOGIN_INFO_FORCE_LOGOUT'">{{translate("Force logout all")}}</el-button>
				</template>
			</el-table-column>
		</el-table>

		<Pagination v-show="total > 0" :total="total" :page.sync="pageNo" :limit.sync="pageSize" @pagination="getList"/>

	</div>
</template>

<script>
	import {UserApi} from '@/api';
	export default {
		name: "ToolBar",
		components: {},
		props: {
			dicts: {
				type: Object,
				default: {}
			},
			queryParams: {
				type: Object,
				default: {},
			},
		},
		data() {
			return {
				pageNo: 1,
				pageSize: 10,
				loading: false,
				total: 0,
				userInfos: {},
				dataList: [],
			}
		},
		mounted() {
		},
		watch: {
			queryParams: function () {
				this.pageNo = 1;
				this.getList();
			},
		},
		methods: {
			async getList() {
				this.loading = true;
				let result = await UserApi.loginInfo.list(this.pageNo, this.pageSize, this.queryParams);
				this.loading = false;
				this.total = result.count || 0;
				this.dataList = result.data || [];
			},
			async expandChange(row, expandedRows){
				if (expandedRows.indexOf(row) < 0) {
					return;
				}
				if (this.userInfos[row.userId] == null) {
					this.userInfos[row.userId] = (await UserApi.user.detail(row.userId)).data;
				}
			},
			forceLogout(row){
				this.$confirm(this.translate(`USER.FORCE_LOGOUT_TIP`, {param: row.userId}),
					this.translate('FROM.WARNING'), {
					confirmButtonText: this.translate('FROM.SUBMIT'),
					cancelButtonText: this.translate('FROM.CANCEL'),
					type: "warning"
				}).then(async () => {
					let result = await UserApi.loginInfo.forceLogout(row.id);
					if (result.success) {
						this.Message.success(this.translate('LOGIN.FORCE_LOGOUT_SUCCESS'));
						this.getList();
					}
				});
			},
			forceLogoutAll(row){
				this.$confirm(this.translate(`USER.ALL_FORCE_LOGOUT_TIP`, {param: row.userId}),
					this.translate('FROM.WARNING'), {
					confirmButtonText: this.translate('FROM.SUBMIT'),
					cancelButtonText: this.translate('FROM.CANCEL'),
					type: "warning"
				}).then(async () => {
					let result = await UserApi.loginInfo.forceLogoutAll(row.id);
					if (result.success) {
						this.Message.success(this.translate('LOGIN.FORCE_LOGOUT_SUCCESS'));
						this.getList();
					}
				});
			},
		}
	};
</script>

<style scoped>

</style>
